import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import loadable from "@loadable/component";
import { AppContextProvider } from "./contexts/app";
import Loading from "./components/Loading";

import "./index.css";

const loadableOpts = { fallback: <Loading /> };

const HomePageComponent = loadable(
  () => import("./pages/HomePage"),
  loadableOpts
);
const AppPrivacyPolicy = loadable(
  () => import("./pages/AppPrivacyPolicy"),
  loadableOpts
);
const WillsForm = loadable(() => import("./pages/WillsForm"), loadableOpts);
const WillPreview = loadable(() => import("./pages/WillPreview"), loadableOpts);
const WillReview = loadable(() => import("./pages/WillReview"), loadableOpts);
const WillPayment = loadable(() => import("./pages/WillPayment"), loadableOpts);

function App() {
  return (
    <AppContextProvider>
      <QueryParamProvider>
        <Router>
          <Switch>
            <Route exact path="/" children={<HomePageComponent />} />
            <Route path="/privacy-policy" children={<AppPrivacyPolicy />} />
            <Route path="/wills-form" children={<WillsForm />} />
            <Route path="/will-preview" children={<WillPreview />} />
            <Route path="/will-review" children={<WillReview />} />
            <Route path="/will-payment" children={<WillPayment />} />
          </Switch>
        </Router>
      </QueryParamProvider>
    </AppContextProvider>
  );
}

export default App;
